@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800|Material+Icons&subset=latin,latin-ext');

$scrollbarBG: #ececec;
$thumbBG: #5ea3c5;

@keyframes fadein {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@keyframes fadein2 {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@keyframes fadein3 {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@keyframes fadeout {
   0% {
      opacity: 1;
   }
   100% {
      opacity: 0;
   }
}

@keyframes fadeinout {
   0% {
      opacity: 0;
   }
   25% {
      opacity: 1;
   }
   85% {
      opacity: 1;
   }
   100% {
      opacity: 0;
      display: 'none';
   }
}

a {
   color: #ccc;
}

a:visited {
   color: #999;
}

.transition-fade {
   opacity: 0;
   overflow: hidden;
}

.transition-fade-enter {
   opacity: 0;
}

.transition-fade-enter-active {
   opacity: 1;
   transition: all 500ms ease-in-out;
   -webkit-transition: all 500ms ease-in-out;
   -moz-transition: all 500ms ease-in-out;
   -o-transition: all 500ms ease-in-out;
}

.transition-fade-exit {
   opacity: 1;
}

.transition-fade-exit-active {
   opacity: 0;
   transition: all 200ms ease-in-out;
   -webkit-transition: all 200ms ease-in-out;
   -moz-transition: all 200ms ease-in-out;
   -o-transition: all 200ms ease-in-out;
}

.transition-fade-exit-done {
   opacity: 0;
   overflow: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   .MuiGrid-grid-sm-4 {
      flex-basis: 300px !important;
   }
}


/* Scrollbars */

*::-webkit-scrollbar {
   width: 10px;
   height: 10px;
}

*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
   background: $scrollbarBG;
}
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
   background-color: $thumbBG;
   border-radius: 6px;
   border: 2px solid $scrollbarBG;
}